
/*
* > COLOR CLASSES
*/

/*
* >> Background Color
*/

// >>> Brand
@each $color, $value in $colors {
  .bg-#{$color} {
    @include text-color-contrast($value);
    background-color: $value;
  }
}

// >>> Brand Transparent
@each $color, $value in $colors {
  .bg-transparent-#{$color} {
    @include text-color-contrast($value);
    @include background-transparent($value);
  }
}

// >>> Theme
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    @include text-color-contrast($value);
    background-color: $value;
  }
}

// >>> Grays
@each $color, $value in $grays {
  .bg-gray-#{$color} {
    @include text-color-contrast($value);
    background-color: $value;
  }
}

.bg-light {
  @include text-color-contrast($light);
  background-color: $light;
}
.bg-dark {
  @include text-color-contrast($dark);
  background-color: $dark;

  .close {
    color: $white;
  }
}


/*
* >> Text Color
*/

// >>> Brand
@each $color, $value in $colors {
  .text-#{$color} {
    // stylelint-disable-next-line declaration-no-important
    color: $value !important;
  }
}

// >>> Theme
@each $color, $value in $theme-colors {
  .text-#{$color} {
    // check for color contrast
    @if $value == $sky {
      // stylelint-disable-next-line declaration-no-important
      color: $azurite !important;
    }
    @else {
      // stylelint-disable-next-line declaration-no-important
      color: $value !important;
    }
  }
}
.text-dark-silver {
  // stylelint-disable-next-line declaration-no-important
  color: $dark-silver !important;
}

/*
* >> Border Colors
*/
@each $color, $value in $colors {
  .border-#{$color} {
    border-color: $value;
  }
}


/*
* > TYPOGRAPHY
*/

// >> Font
// >>> Heading Style
.heading-style {
  font-family: $font-family-headings;
  color: $dark-silver;
}
// >>> Sans
.sans {
  font-family: $font-family-sans-serif;
  font-weight: 500;
}

// >> Lists
/* stylelint-disable-next-line */
.ul-triangles {
  overflow: hidden;

  li {
    position: relative;
    padding-left: 0;
    list-style: none;

    &::before {
      position: absolute;
      top: 8px;
      left: -.8em;
      width: 0;
      height: 0;
      content: "";
      border-top: 4px solid transparent;
      border-right: 0;
      border-bottom: 4px solid transparent;
      border-left: 4px solid $blue;
    }
  }
}
