// Overrides for the Navbar component

// >> .navbar
.navbar {
  padding: $navbar-padding-y $navbar-padding-x;
}

.navbar-nav .nav-link {
  font-weight: $navbar-nav-link-font-weight;
  background-color: inherit;
  border: none;
}

// >> .navbar-nav
.navbar-nav .nav-link,
.navbar-brand {
  padding: $navbar-nav-link-padding-y $navbar-nav-link-padding-x;
}

.navbar-nav-scroll {
  display: flex;
  align-self: stretch;
}

.navbar-light {
  border-color: $navbar-light-border-color;
  border-style: solid;
  border-width: $navbar-light-border-width;

  .nav-item {
    .nav-link {
      color: $navbar-light-link-color;

      &:hover {
        color: $navbar-light-link-active-color;
      }
    }

    &:hover {
      color: $navbar-light-link-active-color;
      background-color: $navbar-light-link-hover-bg;
    }

    &.active,
    &:active {
      .nav-link {
        color: $navbar-light-link-active-color;
        background-color: $navbar-light-link-active-bg;
      }
    }
  }
}

