// Overrides to Mixins

/*
 * > ALERTS
 */
@mixin alert-variant($background) {
  @include text-color-contrast($background);
  background-color: $background;
  border: none;

  .alert-link {
    color: $alert-text-color;
  }
  hr {
    border-top-color: $alert-text-color;
  }
}

/*
 * > BACKGROUND COLORS
 */

// >> Transparent Backgrounds
@mixin background-transparent($bg-color) {
  background-color: rgba($bg-color, .8);
}

// >> Text Color Contrast Check
@mixin text-color-contrast($bg-color) {
  @if $bg-color == $bloom or $bg-color == $white or $bg-color == $sky or $bg-color == $oasis or $bg-color == $cool-gray or $bg-color == $warm-gray or $bg-color == $silver or $bg-color == $leaf or $bg-color == $warning or $bg-color == $light or $bg-color == $gray-100 or $bg-color == $gray-200 or $bg-color == $gray-300 or $bg-color == $gray-400 or $bg-color == $gray-500 {
    // light background color, needs dark text color
    color: $black;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: inherit;
    }
  }
  @else {
    // dark background color, needs light text color
    color: $white;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: inherit;
    }
  }
}


/*
 * > BADGES
 */
@mixin badge-variant($background) {
  // check for inaccessible color contrast
  $badge-text-color: $white;

  @if $background == $sky or $background == $leaf or $background == $light {
    $badge-text-color: $midnight;
  }
  @else if $background == $warning {
    $badge-text-color: $black;
  }
  color: $badge-text-color;
  background-color: $background;
}


/*
 * > BUTTONS
 */

// >> Regular
@mixin button-variant($background) {
  // check for inaccessible color contrast
  $button-text-color: $white;

  @if $background == $sky or $background == $leaf or $background == $light {
    $button-text-color: $midnight;
  }
  @else if $background == $warning {
    $button-text-color: $black;
  }

  color: $button-text-color;
}

// >> Outline
@mixin button-outline-variant($color) {
  color: $color;
  border-color: $color;

  @include hover() {
    background-color: $color;
    border-color: $color;
  }
}


/*
 * > CALLOUTS
 */
@mixin callout-variant($color) {
  border-left-color: $color;

  h2,
  h3,
  h4,
  h5,
  h6,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $color;
  }
}
