// Overrides & custom for the Collapse component

// make entire accordion header clickable for accessibility and UX
.accordion {
  .card-header {
    padding: 0;

    h2,
    h3,
    h4,
    h5,
    h6,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      padding: 0;
    }

    .btn {
      display: block;
      width: 100%;
      padding: .75rem 1.25rem;
      color: $dark-silver;
      text-align: left;

      &::after {
        position: absolute;
        top: -4px;
        right: 25px;
        display: inline-block;
        font-size: 2em;
        content: "\221F";
        transform: rotate(-45deg);
      }

    }
    .btn[aria-expanded="true"] {
      &::after {
        top: 6px;
        transform: rotate(135deg);
      }
    }
  }
}
